import PageH2 from './PageH2'
import PageH3 from './PageH3'
import PageH4 from './PageH4'
import PageHr from './PageHr'
import PageTextList from './PageTextList'
import PageImage from './PageImage'
import PageTabs from './PageTabs'
import PageTab from './PageTab'
import PageTimeline from './PageTimeline'
import PageToolTip from './PageToolTip'
import PageButton from './PageButton'
import PageCustomImg from './PageCustomImg'
import PageCustomCom from './PageCustomCom'

type PageComType = "h2"|"h3"|"hr"|"h4"|"textList"|"img"|"tabs"|"tab"|"timeline"|"toolTip"|"button"|"customImg"|"customCom"


export interface PageTextProps {
  /**渲染文本类型 */
  type?: string
  /**文本内容 */
  value?: string | Array<any> | any
  /**样式 */
  style?: CSSStyleDeclaration
  /**类名 */
  className?: string
  /** 文本类名 */
  textClassName?:string
  /**如果是图片，图片的alt */
  alt?: string
  /**日期 */
  time?: string,
  /**是否激活状态 */
  isActive?: boolean,
  /**子文本 */
  children?: Array<PageTextProps>
  /**如果type为tab时生效，改变tab时触发 */
  onChangeTab?:(index: number)=>void
  /**当前文本索引 */
  index?: number
  /** 图片样式 */
  imgClassName?: string
  /**锚点 */
  anchor?: string
  /**跳转链接 */
  href?: string
 
}

const VersionCom:Record<PageComType,React.FC<PageTextProps>> = {
  h2: PageH2,
  h3: PageH3,
  h4: PageH4,
  hr:PageHr,
  textList: PageTextList,
  img: PageImage,
  tabs: PageTabs,
  tab: PageTab,
  timeline: PageTimeline,
  toolTip: PageToolTip,
  button: PageButton,
  customImg: PageCustomImg,
  customCom:PageCustomCom
}

export default VersionCom
