import React, { useState, useMemo, useEffect } from 'react'
import { PageTextProps } from '../index'
import VersionList from 'components/VersionList'

const PageTabs: React.FC<PageTextProps> = ({ value, className }) => {
  const [activeValue, setActiveValue] = useState(value)
  const [activeIndex, setActiveIndex] = useState<any>(null)
  // 改变当前tab时触发的钩子函数
  const onChangeTab = (index) => {
    setActiveIndex(index)
  }
  useMemo(() => {
    // 用下标来区分数组的数据 ，activeIndex为当前激活下标，根据activeIndex值 更新 源数据 activeValue
    if (activeIndex >= 0) {
      setActiveValue((activeValue) => {
        const newActiveValue =
          Array.isArray(activeValue) &&
          activeValue?.map((child, number) => ({ ...child, isActive: activeIndex === number }))
        return (newActiveValue as Array<any>) || []
      })
    }
  }, [activeIndex])
  useEffect(() => {
    if (Array.isArray(value)) {
      const index = value.findIndex((obj) => {
        return obj.isActive === true
      })
      setActiveIndex(index)
    }
  }, [])
  return (
    <section className={className}>
      <div>
        {Array.isArray(activeValue) &&
          activeValue?.map((items, index) => {
            const { type } = items
            const VersionCom = VersionList[type]
            return <VersionCom onChangeTab={onChangeTab} key={index} index={index} {...items} />
          })}
      </div>
      <div>
        {activeIndex >= 0 &&
          activeValue &&
          activeValue[activeIndex]?.children?.map((child, index) => {
            const { type } = child
            const VersionCom = VersionList[type]
            return <VersionCom key={index + 'child'} {...child} />
          })}
      </div>
    </section>
  )
}

export default PageTabs
