import React, { useMemo } from 'react'
import { PageTextProps } from '../index'
import classnames from 'classnames'

const PageTimeline: React.FC<PageTextProps> = ({ value, className }) => {
  const textClassName = 'text-[18px] leading-[18px] font-medium text-[#1f2d3d]'
  const dataSource = useMemo(() => {
    return value
  }, [className])
  return (
    <div className={classnames('lg:px-[45px]', className)} key="timeline">
      {Array.isArray(dataSource) &&
        dataSource.map((item) => {
          return (
            <span className={textClassName} key={item}>
              {item}
            </span>
          )
        })}
    </div>
  )
}
export default PageTimeline
