import React, { useState, useMemo, useRef, useEffect } from 'react'
import Layout from 'layouts/zh'
import { Seo, PageBottomCard } from 'components/index'
import VersionList from 'components/VersionList'
import PageAside from 'components/VersionList/PageAside'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import dataSource from 'i18n/sa/2.5.json'
import { useScroll } from 'ahooks'
import classnames from 'classnames'

const Sa = () => {
  const ref = useRef<any>() // 右侧导航栏wrapper
  const scroll = useScroll()
  const menuRef = useRef<any>() // 右侧菜单导航
  const [pageAsideClassName, setPageAsideClassName] = useState('')
  const isMb = useIsWindowWidthSmaller()
  useMemo(() => {
    if (!isMb) {
      if (ref.current && menuRef.current) {
        const offset = ref.current.clientHeight - menuRef.current.clientHeight
        if (scroll && scroll.top < offset) {
          setPageAsideClassName('')
        } else {
          setPageAsideClassName('lg:absolute  lg:left-0 lg:bottom-[0px]')
        }
      }
    }
  }, [scroll, isMb])
  useEffect(() => {
    menuRef.current = document.getElementsByClassName('sensors_menu')[0]
  }, [])
  const data = useMemo(() => {
    return dataSource.data
  }, [])
  return (
    <Layout>
      <Seo
        title={dataSource.title}
        description={dataSource.description}
        keywords={dataSource.keywords}
        saTitle={dataSource.title}
      />
      <main className="lg:w-[1126px] lg:bg-[#fff] bg-[#F9FAFC]  lg:mx-auto flex lg:flex-row flex-col-reverse lg:pt-[64px] pt-[4rem] px-[2rem] lg:px-0 ">
        {/* 左侧内容板块 */}
        <div className="lg:w-[870px] lg:pr-[33px] lg:pb-[100px] pb-[60px] relative">
          {data.map((item, index) => {
            const { type } = item
            const VersionCom = VersionList[type]
            return <VersionCom key={type + index} {...item} />
          })}
        </div>
        {/* 右侧菜单栏 */}
        <div ref={ref} className="flex-1 mt-[34px] lg:mt-0  lg:bg-[#f9fafc] lg:relative  ">
          <PageAside
            className={classnames(
              'lg:w-[256px]   lg:px-[20px] lg:pt-[34px] lg:pb-[34px]',
              { 'lg:translate-x-[307px] lg:fixed lg:left-1/2 lg:top-[64px] ': !pageAsideClassName },
              pageAsideClassName,
            )}
          />
        </div>
      </main>
      {/* 尾部 */}
      <PageBottomCard
        title="开启大数据分析与营销科技之旅！"
        desc="立即注册，和神策数据专家一起探讨数字化！"
        leftButtonText="体验 Demo"
        leftButtonHref="/demo/demo.html"
        rightButtonText="预约演示"
        rightButtonHref="/form/parade.html"
      />
    </Layout>
  )
}

export default Sa
