import React from 'react'
import { PageTextProps } from '../index'

const PageHr: React.FC<PageTextProps> = () => {
  const className = 'py-[20px]'
  return (
    <div className={className}>
      <hr className="h-[1px] bg-[#E8E9EC]" />
    </div>
  )
}

export default PageHr
