import React, { useState } from 'react'
import classnames from 'classnames'
import MenuItem from './PageMenuItem'
interface PageMenuProps {
  className?: string
}
const list = [
  {
    title: '神策分析',
    name: 'sa',
    flag: false,
    child: [
      {
        title: '神策分析 2.5',
        href: '/terms/sa-v2_5.html',
        name: 'sa',
        child: [
          {
            title: '产品更新日志',
            link: 'product_log',
          },
          {
            title: '产品生命周期管理',
            link: 'product_life',
          },
        ],
      },
      // {
      //   title: '神策分析 2.4',
      //   href: '/terms/sa-v2_4.html',
      //   name: 'sa',
      //   child: [
      //     {
      //       title: '产品更新日志',
      //       link: 'product_log',
      //     },
      //     {
      //       title: '产品声明周期管理',
      //       link: 'product_life',
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   title: '神策智能运营',
  //   name: 'sf',
  //   flag: false,
  //   child: [
  //     {
  //       title: '神策分析 2.6',
  //       href: '/terms/sa-v2_6.html',
  //       name: 'sf',
  //       child: [
  //         {
  //           title: '产品更新日志',
  //           link: 'product_log',
  //         },
  //         {
  //           title: '产品声明周期管理',
  //           link: 'product_life',
  //         },
  //       ],
  //     },
  //     {
  //       title: '神策分析 2.4',
  //       href: '/terms/sa-v2_6.html',
  //       name: 'sf',
  //       child: [
  //         {
  //           title: '产品更新日志',
  //           link: 'product_log',
  //         },
  //         {
  //           title: '产品声明周期管理',
  //           link: 'product_life',
  //         },
  //       ],
  //     },
  //   ],
  // },
]

const PageMenu: React.FC<PageMenuProps> = ({ className }) => {
  const [state, setState] = useState(list)
  const handleChangeActive = (name) => {
    setState((state) => {
      return state.map((item) => {
        return { ...item, flag: item.name === name }
      })
    })
  }
  return (
    <div className={classnames(className)}>
      <MenuItem handleChangeActive={handleChangeActive} data={state} />
    </div>
  )
}

export default PageMenu
