import React from 'react'
import { PageTextProps } from '../index'
import classnames from 'classnames'
import * as styles from './index.module.less'

const PageToolTip: React.FC<PageTextProps> = ({ className, value, textClassName }) => {
  return (
    <div className={classnames(styles.toolTipRoot, className)}>
      <span className={styles.icon}></span>
      <div className={classnames(textClassName)}>
        {Array.isArray(value) &&
          value.map((item, index) => {
            return (
              <p
                className={classnames(
                  styles.text,
                  'lg:pl-[20px]  text-[14px] text-[#475669] leading-[22px] lg:leading-[20px] lg:block inline-block ',
                )}
                key={item}
              >
                {<span className={classnames(styles.iconMb, { '!hidden': index !== 0 })}></span>}
                <span>{item}</span>
              </p>
            )
          })}
      </div>
    </div>
  )
}

export default PageToolTip
