import React from 'react'
import classnames from 'classnames'
import PageMenu from '../PageMenu'
import PageInputButton from '../PageInputButton'
import Button from 'components/Base/Button'
import * as styles from './index.module.less'

interface PageAsideProps {
  className?: string
}

const data = {
  text: '前往帮助中心了解更多',
  link: 'https://manual.sensorsdata.cn/sa/latest/zh_cn/%E3%80%8C%E7%A5%9E%E7%AD%96%E5%88%86%E6%9E%90%E3%80%8D-121831469.html',
}

const PageAside: React.FC<PageAsideProps> = ({ className }) => {
  return (
    <div className={classnames(styles.menuRoot, 'sensors_menu', className)}>
      {/* <PageInputButton {...data} /> */}
      <Button
        target="_blank"
        className="w-full text-[14px] font-normal"
        btnType="primary"
        icon={styles.icon}
        href={data.link}
      >
        {data.text}
      </Button>
      <PageMenu className="lg:block hidden" />
    </div>
  )
}

export default PageAside
