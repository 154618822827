import React from 'react'
import { PageTextProps } from '../index'
import classnames from 'classnames'
import * as styles from './index.module.less'

const PageTab: React.FC<PageTextProps> = ({ time, isActive, index, onChangeTab }) => {
  const handleClickTab = (index) => {
    return () => {
      onChangeTab?.(index)
    }
  }
  return (
    <span
      className={classnames(
        styles.tab,
        'lg:mr-[10px] mr-[6px] inline-block text-[14px] lg:text-[18px] leading-[30px] px-[10px] lg:px-[12px] ',
        {
          [styles.active]: isActive,
        },
      )}
      onClick={handleClickTab(index)}
    >
      {time}
    </span>
  )
}

export default PageTab
