import React from 'react'
import PageButton from '../PageButton'
import PageCustomImg from '../PageCustomImg'
import PageToolTip from '../PageToolTip'
import PageTimeline from '../PageTimeline'

const PageCustomCom: React.FC<any> = ({ value }) => {
  return (
    <div>
      <PageCustomImg {...value.customImg} className="relative">
        <PageTimeline {...value.timeline} className="lg:px-[45px] lg:flex lg:justify-between   mt-[18px] hidden" />
        <PageToolTip {...value.toolTip} className="lg:flex lg:mt-[46px]" textClassName="lg:flex-1" />
      </PageCustomImg>
      <PageButton {...value.button} className="text-center mt-[40px]" />
    </div>
  )
}

export default PageCustomCom
