import React from 'react'
import classnames from 'classnames'
import { PageTextProps } from '../index'

const PageH2: React.FC<PageTextProps> = ({ value, className, anchor = '' }) => {
  const defaultClassName = 'lg:text-[30px] text-[24px] text-[#2c2c45] leading-[30px] font-medium '
  return (
    <h2 id={anchor} className={classnames(defaultClassName, className)}>
      {value as string}
    </h2>
  )
}

export default PageH2
