import React, { ReactNode } from 'react'
import PageImage from '../PageImage'
import { PageTextProps } from '../index'
const PageCustomImg: React.FC<PageTextProps> = ({ value, children, className }) => {
  return (
    <div className={className}>
      <PageImage imgClassName="h-[440px] lg:h-auto " value={value} />
      {children as ReactNode}
    </div>
  )
}

export default PageCustomImg
