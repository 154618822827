import React from 'react'
import { PageTextProps } from '../index'
import classnames from 'classnames'

const PageH3: React.FC<PageTextProps> = ({ value, className }) => {
  const defaultClassName = 'lg:text-[18px] text-[16px] text-[#1F2D3D] leading-[18px] font-medium '
  return <h3 className={classnames(defaultClassName, className)}>{value}</h3>
}

export default PageH3
