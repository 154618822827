import React, { useMemo } from 'react'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

import { PageTextProps } from '../index'

const PageImage: React.FC<PageTextProps> = ({ value, alt, imgClassName, className }) => {
  const isMb = useIsWindowWidthSmaller()
  const imgUrl = useMemo(() => {
    if (typeof value === 'string') {
      return value
    } else if (typeof value === 'object') {
      const { pc, mb } = value as any
      if (isMb) return mb
      return pc
    } else {
      return value
    }

    // className imgClassName 更新 触发图片样式更新调整
  }, [value, isMb, className, imgClassName])
  return (
    <div className={className}>
      <img className={imgClassName} src={imgUrl} alt={alt} />
    </div>
  )
}

export default PageImage
