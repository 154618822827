import React, { useEffect, useMemo, useState } from 'react'

import * as styles from './index.module.less'
import classnames from 'classnames'

// 菜单列表 递归组件
const MenuItem = ({ data, className = '', titleClassName = '', handleChangeActive }) => {
  return (
    <div className={className}>
      {data.length &&
        data.map((item, index) => {
          const [state, setState] = useState(item)
          const [isActive, setIsActive] = useState(false) //是否激活
          const { title = '', child = [], active = false, link = '', href = '', name = '', flag = false } = state
          const handleClickIcon = (e) => {
            // 阻止向上冒泡
            e.stopPropagation()
            setState((state) => {
              return { ...state, active: !active }
            })
          }
          // 点击标题
          const handleClickTitle = (e) => {
            // 阻止向下捕获
            e.stopPropagation()
            // 若果是激活状态 则点击仅控制菜单展开收起
            if (isActive) {
              setState((state) => {
                return { ...state, active: !active }
              })
            }
            //   锚点链接存在，点击滚动到锚点链接 未来多个同层级 ，需先判断是否在当前页面，后期需优化 ！！！
            else if (link) {
              const anchorEle = document.getElementById(link)
              window.scrollTo({
                top: anchorEle?.offsetTop,
                behavior: 'smooth',
              })
              //   路径链接存在，跳转相关页面
            } else if (href) {
              window.location.href = href
            } else {
              // 都不存在默认展开 or 关闭 该单元列表
              setState((state) => {
                return { ...state, active: !active }
              })
            }
          }

          useEffect(() => {
            // 初始化 默认首个元素展开 待定 后期需优化 ！！！
            setState((state) => {
              return { ...state, active: index === 0 }
            })
            // 初始化根据路径匹配激活状态
            if (window.location.pathname === href) {
              setIsActive(true)
              handleChangeActive?.(name)
            }
          }, [])
          useMemo(() => {
            // 手写出发上级目录 flag状态更新
            setState((state) => {
              return { ...state, flag: item.flag || false }
            })
          }, [item.flag])
          return (
            <div key={title} className={titleClassName}>
              {title && (
                <p
                  className={classnames('text-[14px] text-[#475669] relative cursor-pointer hover:text-[#04CB94]', {
                    'mt-[20px]': !!child.length,
                    'mt-[10px]': !child.length,
                    'text-[#04cb94]': isActive || flag,
                  })}
                  onClick={handleClickTitle}
                >
                  <span>{title}</span>
                  {!!child.length && (
                    <span
                      className={classnames(
                        styles.icon,
                        { [styles.iconActive]: active }, // 控制展开收起箭头方向
                        { [styles.isActive]: isActive || flag }, // 控制激活状态下 展开收起箭头颜色
                      )}
                      onClick={handleClickIcon}
                    />
                  )}
                </p>
              )}
              {active && !!child.length && (
                <MenuItem data={child} titleClassName="pl-[16px]" handleChangeActive={handleChangeActive} />
              )}
            </div>
          )
        })}
    </div>
  )
}

export default MenuItem
