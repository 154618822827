import React from 'react'
import { PageTextProps } from '../index'

const PageTextList: React.FC<PageTextProps> = ({ value }) => {
  return (
    <div>
      {Array.isArray(value) &&
        value?.map((items) => {
          const { name, value, className } = items
          return (
            <div className={className} key={name}>
              {name && <p className="text-[14px] text-[#475669] font-medium">{name}</p>}
              {value && <p className="text-[14px] text-[#475669] leading-[20px]">{value}</p>}
            </div>
          )
        })}
    </div>
  )
}

export default PageTextList
