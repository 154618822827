import React from 'react'
import { PageTextProps } from '../index'
import Button, { ButtonGroup } from 'components/Base/Button'

const PageButton: React.FC<PageTextProps> = ({ className, value }) => {
  return (
    <ButtonGroup className={className}>
      {Array.isArray(value) &&
        value.map((item, index) => {
          return (
            <Button {...item} key={index + 'button'}>
              {item?.text}
            </Button>
          )
        })}
    </ButtonGroup>
  )
}

export default PageButton
